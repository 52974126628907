import { Box} from '@mui/material'
import React, { useState,useEffect } from 'react'
import CustomBox from '../UI/Box/CustomBox'
import CustomText from '../UI/Text/CustomText'
import phone from '../Assets/Animations/phone.json'
import emailAnimation from '../Assets/Animations/email.json'
import MuiPhoneNumber from 'material-ui-phone-number'
import ConfirmButton from '../UI/Button/ConfirmButton'
import OtpBox from '../UI/Box/OtpBox'
import AnimatedIconButton from '../UI/Button/AnimatedIconButton'
import { useDispatch, useSelector } from 'react-redux'
import { setNotificationMode, startUpdateVerificationMethod, updateCountryCode } from '../Slice/ReduxSlice/VisitorSlice'
import OtpVerification from './OtpVerification'
import EmailInput from '../UI/Widgets/EmailInput'
import isEmail from 'validator/lib/isEmail'
import { animatedIconStyle, appTitleStyle, emailInputStyle, imageStyle, logoBoxStyle, otpBottomContainer, otpContainer, otpHeaderStyle, phoneInputContainer, signInContainer } from '../UI/Styles/LoginStyles'
import { alignStyle, commonAlign, commonFontSize } from '../UI/Styles/CommonStyles'
import OverlayLoader from '../UI/Overlay/OverlayLoader'
// import locationPin from '../../../Assets/Images/locationPin.png'
import background from "../Assets/Images/background.png"


const Login = () => {
    const dispatch=useDispatch()
    const [mode,setMode]=useState('')
    const tenantCode = useSelector(state => state.visitorDetails?.tenant?.tenantCode)
    const logo = useSelector(state => state.visitorDetails?.tenant?.logo?.light)
    const [countryCode,setCountryCode]=useState('')
    const [phoneNumber,setPhoneNumber]=useState('')
    const [email,setEmail]=useState('')
    const {otpSent}=useSelector(state=>state?.visitorDetails)
    const [isError, setIsError] = useState(false)
    const [loading,setLoading]=useState(false)
    const handlePhoneNumber=(number,countryData)=>{
        setCountryCode(countryData?.dialCode)
        setPhoneNumber(number)
    }
    const handleMode=(mode)=>{
        if(mode==='phone'){
            setEmail('')
            setIsError(false)
            setMode('phone')
        }
        else {
            setPhoneNumber('')
            setMode('email')
        }
    }
    const handleEmail=(e)=>{
        setIsError(false)
        const val = e.target.value
        setEmail(val)
    }
    const handleBlur=()=>{
     
        // let finalVal
        //     finalVal = email.slice(0, -1)
        //     if (
        //       isEmail(finalVal.trim())) {
        //         console.log('!!!!', isEmail(finalVal.trim()))
        //      //do something
        //     } else {
        //       setIsError(true)
        //     }
      }

    const toggleLoader=()=>{
        setLoading(false)
    }  
    const handleSignIn=()=>{
        let data={
            tenantCode
        }
        
        let regex =/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/; 
        const isValid=String(email)?.trim().match(regex)
        if((mode==='email')){
            data.email=String(email)?.trim()
            if(!isError&&isValid) dispatch(startUpdateVerificationMethod({data,toggleLoader}))
            else {alert(`Please enter a valid ${mode==='phone'?'phone number':'email ID'}`)}
        }
        else if(mode==='phone') {
            data.mobile=phoneNumber?.split(`+${countryCode}`)[1]
            data.countryCode=`+${countryCode}`
            dispatch(updateCountryCode(countryCode))
            if(phoneNumber.length>0) dispatch(startUpdateVerificationMethod({data,toggleLoader}))
           else alert(`Please enter a valid ${mode==='phone'?'phone number':'email ID'}`)
        setLoading(true)
        }
        if(!isError&&isValid){setLoading(true)}
    }  

    useEffect(()=>{
        if(phoneNumber.length<=2){
            setPhoneNumber('+91')
        }
        // console.log({phoneNumber})
    },[phoneNumber])
  return (
      <CustomBox>  
         <CustomBox sx={otpBottomContainer}>
            {!otpSent?
                  <CustomBox>
                        <Box sx={otpHeaderStyle}>
              <div>
                <CustomBox sx={logoBoxStyle}>
                                  <Box component='img' sx={imageStyle} src={logo}/>
                </CustomBox>
                <CustomBox sx={appTitleStyle}>
                        <CustomText sx={{fontSize:'20px',fontWeight:600}}>Choose a verification method</CustomText>
                    
                </CustomBox>
                <CustomBox>
                            <CustomText sx={{fontSize:'16px',textAlign:'center'}}>Verify with your phone or email</CustomText>
                </CustomBox>
            </div>
         </Box>
                {/* <OtpBox title='LOGIN' subTitle={'with your phone or email'}/> */}
                <CustomBox sx={commonAlign}>
                    <CustomBox sx={animatedIconStyle}>
                            <AnimatedIconButton  onclick={()=>handleMode('phone')} animation={phone} />
                            <CustomBox sx={alignStyle}>
                                <CustomText sx={commonFontSize}>OR</CustomText> 
                            </CustomBox>  
                            <AnimatedIconButton onclick={()=>handleMode('email')} animation={emailAnimation} />      
                    </CustomBox>

                </CustomBox>
            {mode&&
            <CustomBox sx={{mt:'6vh'}}>
            {mode==='phone'?
                <CustomBox >
                    <Box sx={phoneInputContainer}>
                                        <MuiPhoneNumber defaultCountry={'in'} 
                                        value={phoneNumber} 
                                        countryCodeEditable={false}
                                        autoFormat={false}
                                        onlyCountries={['in']} 
                                        inputProps={{ style: { textAlign: 'center' },maxLength: 13 }}
                                            onChange={handlePhoneNumber} 
                                            
                                            InputProps={{ disableUnderline: true}} fullWidth disableAreaCodes />
                                            
                    </Box>
                </CustomBox>
                    :
                <CustomBox sx={emailInputStyle}>
                        <EmailInput email={email} handleChange={handleEmail} handleBlur={handleBlur} isError={isError} /> 
                </CustomBox>  }
                <CustomBox sx={signInContainer}>
                    <ConfirmButton backgroundColor={'#FF6520'} title={'SEND OTP'} width={'100%'} borderRadius={'8px'} disabled={(mode==='phone'?phoneNumber.length===0:(email.length===0||isError))} 
                        onclick={handleSignIn}
                    />
                </CustomBox>
                
                </CustomBox>          
            }
            </CustomBox>:
            <>
                <OtpVerification />
            </>}
         </CustomBox>
         <OverlayLoader open={loading}/>
    </CustomBox>
  )
}

export default Login
