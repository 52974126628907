import { Box, Button, CircularProgress, Grid, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { lightTheme } from "../Helpers/ThemeHandler";
import { setVisitorComp } from "../Slice/ReduxSlice/CompSlice";
import {
  clearOtpSent,
  setNewProfile,
  setOtpError,
  setOtpLoading,
  startUpdateVerificationMethod,
  startValidateOtp,
} from "../Slice/ReduxSlice/VisitorSlice";
import CustomBox from "../UI/Box/CustomBox";
import OtpBox from "../UI/Box/OtpBox";
import ConfirmButton from "../UI/Button/ConfirmButton";
import OverlayLoader from "../UI/Overlay/OverlayLoader";
import CloseIcon from '@mui/icons-material/Close'
import {
  defaultFontSizeStyle,
  mtJustifyStyle,
} from "../UI/Styles/CommonStyles";
import {
  otpContainerStyle,
  otpInputStyle,
} from "../UI/Styles/OtpVerificationStyles";
import CustomText from "../UI/Text/CustomText";
import { imageStyle, logoBoxStyle } from "../UI/Styles/LoginStyles";
import Header from "../UI/Header/Header";

const OtpVerification = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        // height: '40%',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        borderRadius: '5%',
        p: 4,
       }
  const dispatch = useDispatch();
  const tenantCode = useSelector(
    (state) => state.visitorDetails?.tenant?.tenantCode
  );
  const logo = useSelector(state => state.visitorDetails?.tenant?.logo?.light)
  const { notificationMode, phoneNumber, email, otpStatus, tenant, secureKey,existingVisitorDetails } =
    useSelector((state) => state?.visitorDetails);
  const personalInfo = useSelector(state=>state.visitorDetails)
  const userArr=[
    { title: "Name", value: existingVisitorDetails?.name },
    { title: "Email", value: existingVisitorDetails?.email },
    { title: "Phone", value: `${existingVisitorDetails?.phone}` },
    { title: "Company", value: existingVisitorDetails?.company },
  ];
  const [otpValue, setOtpValue] = useState("");
  const [userInfoModal, setUserInfoModal] = useState(false);
  const [resendOtpSeconds, setResetOtpSeconds] = useState(60);
  const [loading, setLoading] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [modalUserDetails, setModalUserDetails] = useState(false)

  const handleModal = () => {
 setModalUserDetails(false)
 }

  const startTimer = () => {
    const interval = setInterval(() => {
      setResetOtpSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    setIntervalId(interval);
  };

  const toggleVistorInfoModal = () => {
    setUserInfoModal((prev) => !prev);
  };
  useEffect(() => {
    if (resendOtpSeconds === 0) {
      clearInterval(intervalId);
    }
  }, [resendOtpSeconds, intervalId]);

  useEffect(() => {
    if (otpValue.length === 6) {
      // dispatch(setOtpLoading())
      dispatch(setOtpLoading());
      let data = {
        tenantCode,
        otp: otpValue,
        secureKey,
        building: tenant?.building?._id,
      };
      if (notificationMode === "mobile") {
        data.mobile = phoneNumber?.number;
      } else {
        data.email = email?.mailId;
      }
      dispatch(startValidateOtp(data));

      // setTimeout(()=>{
      //     dispatch(setOtpError())
      // },3000)
    }
  }, [otpValue]);

  const toggleLoader = () => {
    setLoading(false);
    setResetOtpSeconds(60);
    startTimer();
  };
  const handleResendOtp = () => {
    setOtpValue("")
    
    const data = {
      tenantCode: tenantCode,
    };
    if (notificationMode === "mobile") {
      data.mobile = phoneNumber?.number;
      data.countryCode = `+${phoneNumber?.countryCode}`;
    } else {
      data.email = email?.mailId;
    }
    setLoading(true);

    dispatch(startUpdateVerificationMethod({ data, toggleLoader }));
  };
  const handleProfileAction=({creatProfile})=>{
        dispatch(setVisitorComp(creatProfile?"addVisitorDetails":"VisitorForm"));
        if(creatProfile){
            dispatch(setNewProfile())
        }

  }
  useEffect(() => {
    if (otpStatus?.message === "success") {
      if (otpStatus?.profileCreated) {
        toggleVistorInfoModal();
      } else {
        dispatch(setVisitorComp("addVisitorDetails"));
      }
    }
  }, [otpStatus]);

  useEffect(() => {
    startTimer();
    return () => {
      dispatch(clearOtpSent());
    };
  }, []);
  return (
    <>
      <CustomBox sx={{ mt: "10vh" }}>
        <CustomBox sx={logoBoxStyle}>
          <Box component="img" sx={imageStyle} src={logo} />
        </CustomBox>
        <OtpBox
          title="Verify with OTP"
          subTitle={`An OTP has been sent to `}
          textDecorationText={
            notificationMode === "mobile" ? phoneNumber?.number : email?.mailId
          }
          textDecoration={true}
        />
        <CustomBox sx={{ mt: "2vh" }}>
          <OTPInput
            value={otpValue}
            onChange={setOtpValue}
            containerStyle={otpContainerStyle}
            inputStyle={otpInputStyle}
            inputType={"tel"}
            numInputs={6}
            renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
            renderInput={(props) => <input type="number" {...props} />}
            shouldAutoFocus={true}
          />
          {otpStatus?.isLoading ? (
            <CustomBox sx={mtJustifyStyle}>
              <CircularProgress size={20} />
            </CustomBox>
          ) : (
            otpStatus?.message &&
            otpValue.length === 6 && (
              <CustomBox sx={{ mt: "1vh" }}>
                <CustomText
                  sx={{
                    fontSize: "12px",
                    textAlign: "center",
                    color:
                      otpStatus?.message === "success"
                        ? `${lightTheme.palette.green.green3}`
                        : `${lightTheme.palette.red.errorRed}`,
                  }}
                >
                  {otpStatus?.message === "success"
                    ? "Success!"
                    : "The OTP you entered is incorrect. Please try again."}
                </CustomText>
              </CustomBox>
            )
          )}
        </CustomBox>
        <CustomBox sx={{ mt: "5vh" }}>
          <CustomText sx={defaultFontSizeStyle}>
            Request for another OTP in{" "}
          </CustomText>
          <CustomBox sx={{ mt: "1vh" }}>
            <CustomText
              sx={defaultFontSizeStyle}
            >{`${resendOtpSeconds} seconds`}</CustomText>
          </CustomBox>
          <CustomBox sx={mtJustifyStyle}>
            <ConfirmButton
              title={"Resend OTP"}
              height={"6vh"}
              borderRadius={"10px"}
              backgroundColor={resendOtpSeconds === 0 ? "#FF6520" : "#c8c7c9"}
              disabled={!(resendOtpSeconds === 0)}
              onclick={handleResendOtp}
            />
          </CustomBox>
        </CustomBox>
        <Modal open={modalUserDetails} onClose={handleModal}>
          <Box sx={style}>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <CustomText variant="h6" fontWeight={200}>
                  Profile Verification
                </CustomText>
              </Grid>
              <Grid item xs={2}>
                <CloseIcon onClick={handleModal} />
              </Grid>
              <Grid item xs={4}>
                <CustomText variant="subtitle2">Name :</CustomText>
              </Grid>
              <Grid item xs={8}>
                {personalInfo?.name}
              </Grid>
              <Grid item xs={4}>
                <CustomText variant="subtitle2">Email :</CustomText>
              </Grid>
              <Grid item xs={8}>
                {" "}
                {personalInfo?.email}
              </Grid>
              <Grid item xs={4}>
                <CustomText variant="subtitle2">Ph No :</CustomText>
              </Grid>
              {/* <Grid item xs={8}>{personalInfo?.mobile}</Grid> */}
              {/* <Box sx={{display:"column",justifyContent:'center',alignItems:'flex-end',pt:"10%"}}> */}
              <Grid container spacing={2} pt={"10%"}>
                <Grid item xs={12}>
                  <ConfirmButton
                    backgroundColor={"#FF6520"}
                    title={"Continue with sample profile"}
                    width={"100%"}
                    borderRadius={"8px"}
                    // onclick={handleSignIn}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{
                      border: "0.2px solid 	#808080",
                      height: "65%",
                      width: "100%",
                    }}
                  >
                    <CustomText variant="subtitle2" color="#000000">
                      Create New
                    </CustomText>
                  </Button>
                </Grid>
              </Grid>
              {/* </Box> */}
            </Grid>
          </Box>
        </Modal>
        <OverlayLoader open={loading} />
        <Modal open={userInfoModal}>
          <Box sx={style}>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <CustomText sx={{ fontSize: "18px" }}>Profile Info</CustomText>
              </Grid>

              {userArr.map((ele) => {
                return (
                  <Grid item xs={12}>
                    <CustomText
                      sx={{ fontSize: "16px" }}
                    >{`${ele.title} : ${ele.value}`}</CustomText>
                  </Grid>
                );
              })}
              {/* <Grid item xs={8}>{personalInfo?.mobile}</Grid> */}
              {/* <Box sx={{display:"column",justifyContent:'center',alignItems:'flex-end',pt:"10%"}}> */}
              <Grid container spacing={2} pt={"10%"}>
                <Grid item xs={12}>
                  <ConfirmButton
                    backgroundColor={"#FF6520"}
                    title={"Continue with same profile"}
                    width={"100%"}
                    height={"5vh"}
                    borderRadius={"8px"}
                    onclick={() => {
                      handleProfileAction({ creatProfile: false });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ConfirmButton
                    backgroundColor={"#FFFFFF"}
                    title={"Create new profile"}
                    width={"100%"}
                    height={"5vh"}
                    color="#000000"
                    borderRadius={"8px"}
                    onclick={() => {
                      handleProfileAction({ creatProfile: true });
                    }}
                  />
                </Grid>
              </Grid>
              {/* </Box> */}
            </Grid>
          </Box>
        </Modal>
      </CustomBox>
    </>
  );
};

export default OtpVerification;
