import { Grid, Stack } from '@mui/material'
import React from 'react'
import { locationTitleContainer } from '../UI/Styles/ManageVisitorStyles/DateSelectorStyles'
import BottomButton from '../UI/ManageVisitor/Button/Button'
import CardComp from '../UI/Card/CardComp'
import SuccessModal from '../UI/Modal/SuccessModal'
import { useState } from 'react'
import { useEffect } from 'react'
import CustomBox from '../UI/Box/CustomBox'
import CustomText from '../UI/Text/CustomText'
import {setVisitorComp} from '../Slice/ReduxSlice/CompSlice'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { startCreateAppointment } from '../Slice/ReduxSlice/VisitorSlice'
import OverlayLoader from '../UI/Overlay/OverlayLoader'

const ConfirmVisitorDetails = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const [loading,setLoading]=useState(false)
    const dispatch = useDispatch()
    const {appointmentInfo,visitDateTime}=useSelector(state=>state?.visitorInfo)

    const isoString=visitDateTime?.selectedTime?.gmtTime?.toISOString()
    const visitBuilding = useSelector(state => state.visitorInfo?.building?._id)
    const {tenant}=useSelector(state=>state?.visitorDetails)
    const handleAppointmentSuccess=()=>{
        setModalOpen(true)
    }
    const toggleLoading=()=>{
        setLoading(false)
    }
    const handleError=(message)=>{
        alert(message)
    }
    
    const handleConfirm=()=>{
        const time=moment().format('HH:mm');
        const startTime=moment().format('YYYY-MM-DD')
        const startTimeGMT=new Date(`${startTime} ${time}`)
        const startTimeISO=startTimeGMT.toISOString()
        const endTime=startTimeGMT
        endTime.setMinutes(endTime.getMinutes()+1440 )
        const endTimeISO=endTime.toISOString()
        const data={
            location:tenant?.location?._id,
            building:tenant?.building?._id,
            host:appointmentInfo?.host?._id,
            hostName:appointmentInfo?.host?.fullName,
            hostEmail:appointmentInfo?.host?.email,
            purpose:appointmentInfo?.purpose,
            startTime:startTimeISO,
            endTime:endTimeISO
        }
        setLoading(true)
        dispatch(startCreateAppointment({data,handleAppointmentSuccess,handleError,toggleLoading}))
    }
    const visitInfo = [
      { title: "Building", data: tenant?.building?.name},
      {
        title: "At",
        data: `${moment().format("DD MMM YYYY")}, ${moment(
          visitDateTime?.selectedTime?.gmtTime
        ).format("hh:mm A")}`,
      },
      { title: "To meet", data: `${appointmentInfo?.host?.fullName}` },
      {
        title: "Purpose of visit",
        data: `${appointmentInfo?.purpose}`,
      },
    ];

    useEffect(() => {
        if (modalOpen) {
            setTimeout(() => {
                dispatch(setVisitorComp('otpPage'))
                setModalOpen(false)
            }, 3000)
        }
    }, [modalOpen])

    return (
        <CustomBox sx={{p:'16px'}}>
            <CustomBox sx={locationTitleContainer}>
                <CardComp padding={'16px'} border='20px'>
                    <Grid container spacing={3}>
                        {visitInfo?.map((ele) => {
                            return (
                                <Grid item xs={12} key={ele}>
                                    <Stack direction={'row'}>
                                        {/* {ele.icon} */}
                                        <CustomBox pl={1}>
                                            <CustomText sx={{ fontSize: '16px', fontWeight: '600' }}>{ele?.title}</CustomText>
                                        </CustomBox>
                                    </Stack>
                                    <CustomBox pl={3.5}>
                                        <CustomText sx={{ fontSize: '16px' }}>{ele.data}</CustomText>
                                    </CustomBox>
                                </Grid>
                            )
                        })}
                    </Grid>
                </CardComp>
                <CustomBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <BottomButton title='Confirm' onclick={handleConfirm} />
                </CustomBox>
                <SuccessModal open={modalOpen} message={'Your visit was booked successfully and has been notified to the host!'} />
            </CustomBox>

            <OverlayLoader open={loading} />
        </CustomBox>
    )
}

export default ConfirmVisitorDetails
