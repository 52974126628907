import {
  Avatar,
  Grid,
  IconButton,
  Modal,
  TextField,
  Box,
  Stack,
  Typography,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getRelativeHeight,
  getRelativeWidth,
} from "../../Helpers/RelativeSize";
import CustomBox from "../Box/CustomBox";
import { commonJustifyContent } from "../Styles/CommonStyles";
import PersonIcon from "@mui/icons-material/Person";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import isEmail from "validator/lib/isEmail";
import MuiPhoneNumber from "material-ui-phone-number";
import { lightTheme } from "../../Helpers/ThemeHandler";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmailIsError,
  setPersonalInfo,
  setProfileImage,
  startUploadFile,
} from "../../Slice/ReduxSlice/VisitorSlice";
import CustomText from "../Text/CustomText";
import cameraIcon from "../../Assets/Images/camera.svg";
import folderIcon from "../../Assets/Images/folder.svg";
import {
  inputStyle,
  AlignedBox,
  style,
} from "../../UI/Styles/ManageVisitorStyles/ModalStyles";
import { startValidateEntryPointQr } from "../../Slice/ReduxSlice/VisitorSlice";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";

const PersonalInfo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const notificationMode = useSelector(
    (state) => state?.visitorDetails?.notificationMode
  );
  const personalInfo = useSelector(
    (state) => state?.visitorDetails?.personalInfo
  );
  const profileImage = useSelector(
    (state) => state?.visitorDetails?.idProof?.profileImage
  );
  const fileReadUrl = useSelector(
    (state) => state.visitorDetails?.tenant?.fileReadUrl
  );
  // const profileImageFile = profileImage;

  const email = personalInfo?.email;
  const nameField = personalInfo?.name;
  const phone = personalInfo?.phone;
  const dispatch = useDispatch();
  const isError = personalInfo.emailIsError;
  // const [companyName,setCompanyName]=useState('')
  const companyName = personalInfo?.companyName;
  const handleEmailChange = (e) => {
    const val = e.target.value;
    // setEmail(val)
    dispatch(setPersonalInfo({ email: val }));
  };
  const handleChange = (type, e, data) => {
    if (type === "company")
      dispatch(setPersonalInfo({ company: e.target.value }));
    else if (type === "name")
      dispatch(setPersonalInfo({ name: e.target.value }));
    else if (type === "phone") {
      const updatedNumber = e;
      dispatch(
        setPersonalInfo({
          phoneNumber: updatedNumber,
          countryCode: `+${data.dialCode}`,
        })
      );
    }
  };
  const handleBlur = () => {
    let finalVal;
    // finalVal = email.slice(0, -1)
    // console.log({finalVal})
    if (isEmail(email.trim())) {
      dispatch(setEmailIsError(false));
      //do something
    } else {
      dispatch(setEmailIsError(true));
    }
  };

  const handleImageUploadSuccess = (awsFile) => {
    dispatch(setProfileImage(awsFile));
  };

  const handleCapture = (target) => {
    setDisabled(true);
    if (target.files) {
      if (target.files.length !== 0) {
        const image = target.files[0];
        let formData = new FormData();
        formData.append("file", image);
        // dispatch(setProfileImage(image));
        dispatch(
          startUploadFile({ formdata: formData, handleImageUploadSuccess })
        );
        setIsModalOpen(false);
      }
    }
  };

  const getImageURL = () => {
    if (profileImage) {
      return URL.createObjectURL(profileImage);
    } else {
      return null;
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (phone?.length <= 2) {
      handleChange("phone", "+91", {
        name: "India",
        dialCode: "91",
        countryCode: "in",
      });
    }
  }, [phone]);

  return (
    <CustomBox sx={{ mt: "2vh" }}>
      <CustomBox sx={commonJustifyContent}>
        {profileImage ? (
          <Avatar
            src={`${fileReadUrl}${profileImage}`}
            sx={{
              height: getRelativeHeight("150px"),
              width: getRelativeWidth("150px"),
            }}
          />
        ) : (
          <Avatar
            sx={{
              height: getRelativeHeight("150px"),
              width: getRelativeWidth("150px"),
            }}
          >
            <PersonIcon />
          </Avatar>
        )}
      </CustomBox>
      <CustomBox sx={commonJustifyContent}>
        <IconButton onClick={handleOpenModal}>
          <ModeEditOutlineOutlinedIcon fontSize="small" />
        </IconButton>
      </CustomBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            value={nameField}
            name="name"
            autoComplete="off"
            inputProps={{ maxLength: 30, style: { textAlign: "center" } }}
            sx={{
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& > fieldset": {
                  border: `1px solid ${
                    nameField.length === 0 ? "red" : "#FF6520"
                  }`,
                },
              },
              width: "100%",
              fontSize: getRelativeWidth("16px"),
              backgroundColor: "#ffffff",
            }}
            placeholder="Your Name"
            // error={nameField?.length == 0 ? true : false}
            // label={nameField?.length == 0 ? 'Name cannot be empty' : ''}
            onChange={(e) => handleChange("name", e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{ color: "#000000", fontSize: "20px", fontWeight: 500 }}
                  >
                    Aa
                  </Typography>
                  {/* Replace with your alphabet icon component */}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ backgroundColor: "#ffffff" }}>
          <TextField
            variant="outlined"
            value={email}
            disabled={notificationMode === "email"}
            name="email"
            autoComplete="off"
            sx={{
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& > fieldset": {
                  border: `1px solid ${isError ? "red" : "#FF6520"}`,
                },
              },
              width: "100%",
              fontSize: getRelativeWidth("16px"),
            }}
            inputProps={{ maxLength: 30, style: { textAlign: "center" } }}
            style={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FF6520",
              },
              "&:hover > .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FF6520",
              },
              height: "6vh",
              width: "100%",
              borderRadius: "10px",
              color: isError && email.length !== 0 ? "red" : "#000000",
            }}
            placeholder={"Your Email ID"}
            onBlur={handleBlur}
            onChange={handleEmailChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MarkunreadOutlinedIcon />
                  {/* Replace with your alphabet icon component */}
                </InputAdornment>
              ),
            }}
            // error={nameField?.length == 0 ? true : false}
            // label={nameField?.length == 0 ? 'Name cannot be empty' : ''}
            // onChange={(e) => handleNameField(e)}
          />
          {isError && (
            <CustomBox sx={{ mt: "10px" }}>
              <CustomText sx={{ fontSize: "16px", color: "#FF9494" }}>
                Please enter a valid email ID
              </CustomText>
            </CustomBox>
          )}
        </Grid>
        <Grid item xs={12} sx={{ backgroundColor: "#ffffff" }}>
          <CustomBox
            sx={{
              border: `solid 1px ${lightTheme.palette.grey.grey3}`,
              p: "10px",
            }}
          >
            <MuiPhoneNumber
              defaultCountry={"in"}
              autoFormat={false}
              value={phone}
              countryCodeEditable={false}
              disabled={notificationMode === "mobile"}
              onlyCountries={["in"]}
              inputProps={{ style: { textAlign: "center" }, maxLength: 13 }}
              onChange={(e, data) => handleChange("phone", e, data)}
              InputProps={{ disableUnderline: true }}
              fullWidth
              disableAreaCodes
            />
          </CustomBox>
        </Grid>
        <Grid item xs={12} sx={{ backgroundColor: "#ffffff" }}>
          <TextField
            variant="outlined"
            value={companyName}
            onChange={(e) => handleChange("company", e)}
            name="name"
            autoComplete="off"
            inputProps={{ maxLength: 30, style: { textAlign: "center" } }}
            sx={{
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& > fieldset": { border: `1px solid ${"#FF6520"}` },
              },
              width: "100%",
              fontSize: getRelativeWidth("16px"),
            }}
            placeholder="Your Company Name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ApartmentOutlinedIcon />
                  {/* Replace with your alphabet icon component */}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={style}>
          <Grid container>
            <Grid item xs={6}>
              <AlignedBox>
                <label htmlFor="icon-button-file">
                  <Stack spacing={1}>
                    <Box display="flex" justifyContent={"center"}>
                      <img src={cameraIcon} width="26px" />
                    </Box>
                    <CustomText sx={{ fontSize: "16px", color: "#212121" }}>
                      Click an image
                    </CustomText>
                  </Stack>
                </label>

                <input
                  accept="image/*"
                  style={inputStyle}
                  id="icon-button-file"
                  type="file"
                  capture="environment"
                  onChange={(e) => handleCapture(e.target)}
                />
              </AlignedBox>
            </Grid>
            <Grid item xs={6}>
              <AlignedBox>
                <label htmlFor="choose-file" id="choose-file-label">
                  <Stack spacing={1}>
                    <Box display="flex" justifyContent={"center"}>
                      <img src={folderIcon} />
                    </Box>
                    <CustomText sx={{ fontSize: "16px", color: "#212121" }}>
                      Upload from Gallery
                    </CustomText>
                  </Stack>
                </label>

                <input
                  name="uploadDocument"
                  type="file"
                  id="choose-file"
                  accept="image/*"
                  style={inputStyle}
                  onChange={(e) => {
                    handleCapture(e.target);
                  }}
                />
              </AlignedBox>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </CustomBox>
  );
};

export default PersonalInfo;
