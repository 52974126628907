import { Box, Stack, Step, StepLabel, Stepper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getRelativeWidth } from "../Helpers/RelativeSize";
import CustomBox from "../UI/Box/CustomBox";
import { imageStyle } from "../UI/Styles/LoginStyles";
import CustomText from "../UI/Text/CustomText";
import { styled } from "@mui/material/styles";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import { useDispatch, useSelector } from "react-redux";
import { ColorlibConnector } from "../UI/Stepper/stepperConnector";
import PersonalInfo from "../UI/Widgets/PersonalInfo";
import ConfirmButton from "../UI/Button/ConfirmButton";
import {
  clearVisitorDetails,
  setUpdatedStep,
  startCreateNewProfile,
  startCreateVisitorProfile,
} from "../Slice/ReduxSlice/VisitorSlice";
import IdProof from "../UI/Widgets/IdProof";
import TermsCondition from "../UI/Widgets/TermsCondition";
import SuccessModal from "../UI/Modal/SuccessModal";
import { setVisitorComp } from "../Slice/ReduxSlice/CompSlice";

const VisitorDetails = () => {
  const stepsData = useSelector(
    (state) => state.visitorDetails?.stepsData?.data
  );
  const currentStep = useSelector(
    (state) => state.visitorDetails?.stepsData?.currentStep
  );
  const personalInfo = useSelector(
    (state) => state?.visitorDetails?.personalInfo
  );
  const idProof = useSelector((state) => state.visitorDetails?.idProof);
  const termsCondition = useSelector(
    (state) => state.visitorDetails.termsConditions?.agreed
  );
  const { profileCompletionUrl } = useSelector(
    (state) => state?.visitorDetails
  );
  const { notificationMode } = useSelector((state) => state.visitorDetails);
  const [successModal, setSuccessModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  const handleVisitorProfileCreation = () => {
    setSuccessModal(true);
  };
  const handleNextClick = () => {
    if (currentStep === 2) {
      let profile = {
        // countryCode:personalInfo?.countryCode,
        name: personalInfo?.name,
        companyName: personalInfo?.companyName,
        picture: idProof?.profileImage,
        idProof: idProof?.awsFile,
        idType: idProof?.type?._id,
        acceptedAgreement: termsCondition,
      };
      if (notificationMode === "mobile")
        profile.email = personalInfo?.email?.trim();
      else if (notificationMode === "email") {
        profile.mobile = personalInfo?.phone?.split(
          personalInfo.countryCode
        )[1];
        profile.countryCode = personalInfo.countryCode;
      }
      const data = {
        id: personalInfo?.visitorId,
        profile,
        handleVisitorProfileCreation: handleVisitorProfileCreation,
      };
      if (personalInfo?.createNewProfile) {
        dispatch(startCreateNewProfile(data));
      } else {
        dispatch(startCreateVisitorProfile(data));
      }
    } else {
      dispatch(setUpdatedStep());
    }
  };

  const handleStepClick = (stepVal) => {
    if (stepVal < currentStep) {
      dispatch(setUpdatedStep({ step: stepVal }));
    }
  };
  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    }),
  }));

  const ColorlibStepIcon = (props) => {
    const { active, completed, className } = props;

    const icons = {
      1: <PersonIcon />,
      2: <VideoLabelIcon />,
      3: <ArticleIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  };
  useEffect(() => {
    if (currentStep === 0) {
      const phone =
        notificationMode === "mobile"
          ? personalInfo?.phone
          : personalInfo?.phone?.split("+91");
      const isDisabled =
        !personalInfo?.name ||
        !personalInfo?.email ||
        !phone[1] ||
        !personalInfo.companyName ||
        personalInfo.emailIsError ||
        !idProof?.profileImage;
      setDisabled(isDisabled);
    } else if (currentStep === 1) {
      const isDisabled = idProof.type === "none" || !idProof.file;
      setDisabled(isDisabled);
    } else {
      const isDisabled = !termsCondition;
      setDisabled(isDisabled);
    }
  }, [personalInfo, idProof, termsCondition]);

  useEffect(() => {
    if (successModal) {
      setTimeout(() => {
        dispatch(
          setVisitorComp(profileCompletionUrl ? "thankyou" : "VisitorForm")
        );
      }, 3000);
    }
  }, [successModal]);

  useEffect(() => {
    return () => {
      dispatch(clearVisitorDetails());
    };
  }, []);

  return (
    <>
      <CustomBox sx={{ p: "16px" }}>
        <CustomBox>
          <CustomBox sx={{ mt: "2vh" }}>
            <CustomText
              sx={{ fontSize: "18px", fontWeight: 600, textAlign: "center" }}
            >
              Complete your profile
            </CustomText>
          </CustomBox>
          <CustomBox sx={{ mt: "1vh" }}>
            <Stack sx={{ width: "100%" }} spacing={4}>
              <Stepper
                alternativeLabel
                activeStep={currentStep}
                connector={<ColorlibConnector />}
              >
                {stepsData.map((label, i) => (
                  <Step key={label} onClick={() => handleStepClick(i)}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
          </CustomBox>
        </CustomBox>
        <CustomBox sx={{ height: "65vh" }}>
          {currentStep === 0 ? (
            <PersonalInfo />
          ) : currentStep === 1 ? (
            <IdProof />
          ) : (
            <TermsCondition />
          )}
        </CustomBox>
        <CustomBox sx={{ mt: "5vh" }}>
          <ConfirmButton
            title={"NEXT"}
            disabled={disabled}
            backgroundColor="#FF6520"
            onclick={handleNextClick}
            width={"100%"}
          />
        </CustomBox>
        <SuccessModal
          open={successModal}
          message={`${
            personalInfo?.createNewProfile ? "New" : "Your"
          } profile has been successfully created!`}
        />
      </CustomBox>
    </>
  );
};

export default VisitorDetails;
