import {Modal, Stack } from '@mui/material'
import React from 'react'
import correct from '../../Assets/Images/correct.svg'
import CustomBox from '../Box/CustomBox'
import CustomText from '../Text/CustomText'

const SuccessModal = (props) => {
    const {open,message}=props
    const modalStyle={
        position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: '24px',
    p: '16px',
    paddingLeft:'20px',
   
    outline:'none',
    borderRadius:'10px'}
  return (
    <>
         <Modal open={open}>
                    <CustomBox sx={modalStyle}>
                      <Stack pb={2} p={2}>
                        <CustomBox sx={{display:'flex',justifyContent:'center',pb:'2px'}} >
                          <img src={correct}  width={'75px'}/>
                        </CustomBox>
                        <CustomBox sx={{pb:'16px'}}></CustomBox>
                            <CustomText
                            // variant="h5"
                            sx={{   textAlign:"center",
                            fontWeight:"bold",
                            fontSize:'24px'}}

                        
                            
                            >
                            Thank you!
                            </CustomText>
                        <CustomText
                          sx={{ textAlign:"center",
                          fontSize:'16px'}}
                        
                        >
                         {message}
                        </CustomText>                        
                      </Stack>
                    </CustomBox>
                  </Modal>
    </>
  )
}

export default SuccessModal
