export const getRelativeHeight=(height)=>{
    let heightInPixels
    const heightToScale=!((String(height).includes('%'))||(String(height).includes('vh')))

    heightInPixels=heightToScale?height?.split('px')[0]:height
    const scaledHeight=heightToScale?(`${(100/896)*Number(heightInPixels)}vh`):height  

    return scaledHeight
}   

export const getRelativeWidth=(width)=>{
    let widthInPixels
    const widthToScale=!((String(width).includes('%'))||(String(width).includes('vw')))
    widthInPixels=widthToScale?width?.split('px')[0]:width
    const scaledWidth=widthToScale?(`${(100/414)*Number(widthInPixels)}vw`):width  
    return scaledWidth
}