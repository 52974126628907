import { Checkbox, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTermsAndConditions, startFetchAgreement } from '../../Slice/ReduxSlice/VisitorSlice'
import CustomBox from '../Box/CustomBox'
import CustomText from '../Text/CustomText'

const TermsCondition = () => {
    const dispatch=useDispatch()
    const {termsConditions}=useSelector(state=>state?.visitorDetails)
    const checked=termsConditions?.agreed
    const formattedString = termsConditions?.general?.replace(/\\r\\n/g, '\n').replace(/\\'/g, "'");

    useEffect(()=>{
        dispatch(startFetchAgreement())
    },[])
    
    const handleTermsCondition=(e)=>{
        dispatch(setTermsAndConditions({agreed:e.target.checked}))
    }
  return (
    <CustomBox sx={{mt:"2vh"}}>
        <CustomBox sx={{height:'60vh',maxHeight:'60vh',overflow:'scroll',backgroundColor:'#FFFFFF', border:'solid 1px #D9D9D9'}}>
            <TextField
            id="outlined-textarea"
            label=""
            placeholder="Placeholder"
            multiline
            fullWidth
            inputProps={
                { readOnly: true, }
            }
            value={formattedString}
            />
        </CustomBox>
        <CustomBox sx={{display:'flex',mt:'2vh'}}>
            <CustomBox>
                <Checkbox
                    checked={checked}
                    onChange={handleTermsCondition}
                    inputProps={{ 'aria-label': 'controlled' }}
                    />
            </CustomBox>
            <CustomBox sx={{display:'flex',alignItems:'center'}}>
                 <CustomText sx={{fontSize:'16px'}}> I agree to the terms and conditions</CustomText>
            </CustomBox>
            
        </CustomBox>
    </CustomBox>
  )
}

export default TermsCondition
