import React from "react";
import { useSelector } from "react-redux";
import Login from "./Login";
import VisitorDetails from "./VisitorDetails";
import DateTimeSelector from "./DateTimeSelector";
import VisitDetails from "./VisitorInformation";
import ConfirmVisitorDetails from "./ConfirmVisitorDetails";
import Header from "../UI/Header/Header";
import VisitorForm from "./VisitorForm";
import { Box } from "@mui/material";
import background from "../Assets/Images/background.png";
import ThankYou from "./ThankYou";

const Main = () => {
  const comp = useSelector((state) => state?.visitorComp?.title);
  return (
    <>
      <Box
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),url(${background})`, // Add a semi-transparent white overlay
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {comp !== "otpPage" && comp !== "thankyou" && <Header />}
        {comp === "otpPage" ? (
          <Login />
        ) : comp === "dateTimeSelector" ? (
          <DateTimeSelector />
        ) : comp === "VisitDetails" ? (
          <VisitDetails />
        ) : comp === "ConfirmVisitorDetails" ? (
          <ConfirmVisitorDetails />
        ) : comp === "addVisitorDetails" ? (
          <VisitorDetails />
        ) : comp === "VisitorForm" ? (
          <VisitorForm />
        ) : comp === "thankyou" ? (
          <ThankYou />
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default Main;
