import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
    title: 'otpPage',
}

const VisitorCompSlice = createSlice({
    name: 'VisitorCompSlice',
    initialState,
    reducers: {
        setVisitorComp: (state, action) => {
            state.title = action.payload
        }
    }
})

export const { setVisitorComp } = VisitorCompSlice.actions

export default VisitorCompSlice.reducer
