import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GET } from '../../Helpers/http'

const initialState = {
    
    visitDateTime: { selectedDate: '', selectedTime: '',startTime:'',endTime:'' },
    appointmentInfo: { host: '', purpose: '', isLoaded: false },
    hostNames: [],
    building:''
}

export const startFetchHostByName=createAsyncThunk("VisitorSlice",async(data,{dispatch,rejectWithValue})=>{
    try{
        const response=await GET(`v1/visitor/hostSearch?name=${data}`)
        if(response.status===200){
            return response.data
        }
        else {
        }
    }
    catch(err){
         console.log({err})
    }
  })


const VisitorInfoSlice = createSlice({
    name: 'VisitorInfoSlice',
    initialState,
    reducers: {
        setVisitDateTime: (state, action) => {
            state.visitDateTime.selectedDate = action.payload?.date
            state.visitDateTime.selectedTime = action.payload?.time
            state.visitDateTime.startTime=action.payload?.startTime
            state.visitDateTime.endTime=action.payload?.endTime
        },
        setAppointmentInfo: (state, action) => {
            state.appointmentInfo.host = action.payload?.host
            state.appointmentInfo.purpose = action.payload?.purpose
            state.appointmentInfo.isLoaded = true
        },
        setHostNames: (state, action) => {
            const hostNames = action.payload?.hostNames;
            if (hostNames) {
                state.hostNames = hostNames;
                state.isLoaded = true;
            } else {
                console.error('Invalid payload for setHostNames action:', action.payload);
            }
        },

        setBuildingName: (state, action) => {
            const buildingName = action.payload?.building;
            if (buildingName) {
                state.building = buildingName;
            } else {
                console.error('Invalid payload for setBuildingName action:', action.payload);
            }
        },
       

    },
    extraReducers(builder) {
        builder.addCase(startFetchHostByName.fulfilled,(state,action)=>{
            if(action.payload){
            state.hostNames=action.payload
            }
            
        })}
})

export const { setVisitDateTime, setAppointmentInfo, setHostNames, setBuildingName } = VisitorInfoSlice.actions

export default VisitorInfoSlice.reducer
