import React from 'react';
import { Stack } from '@mui/material';
import CustomBox from '../../UI/Box/CustomBox';
import Chip from '@mui/material/Chip'
import ThemeProvider from '@mui/material';
import { lightTheme } from '../../Helpers/ThemeHandler';

const PurposeChips = ({ visitPurposes, selectedPurpose, handlePurposeClick }) => {
    return (
        <>
            {visitPurposes.map((purpose) => (
                <CustomBox key={purpose}>
                    <Chip
                        label={purpose}
                        variant="outlined"
                        onClick={() => handlePurposeClick(purpose)}
                        size="medium"
                        sx={{ height: '40px', border: 'solid 1px #AAAAAA' }}
                        style={{
                            backgroundColor: selectedPurpose === purpose ? lightTheme.palette.orange.orange : lightTheme.palette.background.default,
                            color: selectedPurpose === purpose ? lightTheme.palette.background.default : lightTheme.palette.white.contrastText,
                            fontSize: '16px',
                        }}
                    />
                </CustomBox>
            ))}
        </>
    );
};

export default PurposeChips;
