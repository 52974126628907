import { getRelativeHeight, getRelativeWidth } from "../../Helpers/RelativeSize"
import { lightTheme } from "../../Helpers/ThemeHandler"

export const otpContainer={
    // backgroundColor:lightTheme.palette.white.main
}

export const otpHeaderStyle={
    maxWidth: '100vw', overflowX: 'hidden',
    // backgroundColor: lightTheme.palette.white.main
}

export const logoBoxStyle={display:'flex', justifyContent:'center',pt:'60px'}

export const imageStyle={height:getRelativeHeight('40px'), width:getRelativeWidth('150px')}

export const appTitleStyle={zIndex:1,position:'relative',mt:'60px',textAlign:'center',display:'flex',justifyContent:'center'}

export const otpBottomContainer = {
    height: '65vh',
    // backgroundColor: lightTheme.palette.white.main
}

export const animatedIconStyle={display:'flex',mt:'2vh'}

export const phoneInputContainer={ border: `solid 1px ${lightTheme.palette.grey.grey3}`, borderRadius: '8px',m:"16px", p:'10px',mt:getRelativeHeight('24px') , backgroundColor: '#FFFFFF',}

export const emailInputStyle={px:'24px',pb:'16px'}

export const signInContainer={px:'80px', pt:"350px"}