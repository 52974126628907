import { IconButton } from '@mui/material'
import React from 'react'
import Lottie from 'react-lottie';
import { getRelativeHeight, getRelativeWidth } from '../../Helpers/RelativeSize';

const AnimatedIconButton = (props) => {
    const {onclick,animation,disabled}=props
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animation,
        
      };
  return (
    <>
      <IconButton disabled={disabled} sx={{backgroundColor:'#F8F6F4',mx:'5vw', boxShadow:'15', "&:hover, &.Mui-focusVisible, &:active": {backgroundColor: '#F8F6F4'}}} onClick={onclick} >
                         {/* <img src={phoneIcon} height={'40px'} width={'40px'}/> */}
                         <Lottie 
                        options={defaultOptions}
                        height={getRelativeHeight('45px')}
                        width={getRelativeWidth('45px')}
            />
                    </IconButton>
    </>
  )
}

export default AnimatedIconButton
