import { FormControl, OutlinedInput } from '@mui/material'
import React from 'react'

const VisitorInput = (props) => {
    const { name, value, onChange, placeholder } = props
    return (

        <FormControl fullWidth={true}>
            <OutlinedInput
                placeholder={placeholder || "Enter a description for your meeting"}
                minRows={5}
                sx={{ backgroundColor: '#FFFFFF', width: '100%', borderRadius: '10px' }}
                name={name}
                value={value}
                onChange={onChange}
                multiline={true}
            />
        </FormControl>
    )
}

export default VisitorInput