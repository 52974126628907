import { Box } from "@mui/material";
import React from "react";
import CustomText from "../UI/Text/CustomText";
import Lottie from "react-lottie";
import thankyou from "../Assets/Animations/tick.json";
const ThankYou = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: thankyou,
  };
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        {/* <CustomText sx={{ fontSize: "20px", textAlign: "center" }}>
          Thank you!
        </CustomText> */}
        <Lottie options={defaultOptions} height={200} width={200} />

        <CustomText sx={{ fontSize: "20px", textAlign: "center" }}>
          Your profile is completed!
        </CustomText>
      </Box>
    </Box>
  );
};

export default ThankYou;
