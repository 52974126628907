import logo from "./logo.svg";
import "./App.css";
import {
  Box,
  CssBaseline,
  Modal,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { lightTheme } from "./Helpers/ThemeHandler";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsLoaded,
  startUpdateVerificationMethod,
  startValidateEncryptedString,
  startValidateEntryPointQr,
} from "./Slice/ReduxSlice/VisitorSlice";
import Lottie from "react-lottie";
import loader from "./Assets/Animations/animation_ljzofmow.json";
import { getRelativeHeight } from "./Helpers/RelativeSize";
import Main from "./Components/Main";
import CustomBox from "./UI/Box/CustomBox";
import SessionExpired from "./UI/Widgets/SessionExpired";
import { useLocation } from "react-router-dom";
import VisitorDetails from "./Components/VisitorDetails";
import OtpVerification from "./Components/OtpVerification";
import { setVisitorComp } from "./Slice/ReduxSlice/CompSlice";
import ThankYou from "./Components/ThankYou";
import axios from "axios";

function App() {
  const dispatch = useDispatch();
  const isLoaded = useSelector((state) => state?.visitorDetails?.isLoaded);
  const loaded = isLoaded?.loaded;
  const location = useLocation();
  const error = isLoaded?.error;

  const [csrfLoaded, setCsrfLoaded] = useState(false)

  const toggleOtpSentLoader = () => {
    dispatch(setVisitorComp("otpPage"));

    dispatch(setIsLoaded());
  };
  const handleSendOtp = (data) => {
    dispatch(
      startUpdateVerificationMethod({
        data,
        toggleLoader: toggleOtpSentLoader,
      })
    );
  };
  useEffect(() => {
    if (csrfLoaded) {
      const entryPointQrPath = location?.search?.split("qrcode/");
      const profilePath = location?.search?.split("profile/");
      if (entryPointQrPath?.length === 2) {
        //   const url = location.search.split("?")[1];
        //   const tenantCode = url?.split("&")[0]?.split("=")[1];
        const entryPointQrCode =
          entryPointQrPath?.length === 2 ? entryPointQrPath[1] : null;
        const data = { encryptedCode: entryPointQrCode };
        dispatch(startValidateEntryPointQr(data));
      } else if (profilePath?.length === 2) {
        //   console.log({ urlParts: urlParts });
        //   console.log({ profileIndex });
        const encryptedCode = profilePath?.length === 2 ? profilePath[1] : null;
        if (encryptedCode) {
          dispatch(
            startValidateEncryptedString({
              body: { encryptedCode },
              handleSendOtp,
            })
          );
        }
      } else {
        const tenantCode = localStorage.getItem("tenant");
        const qrcode = localStorage.getItem("qrcode");
        dispatch(startValidateEntryPointQr({ qrcode, tenant: tenantCode }));
      }
    }
    // dispatch(startValidateEntryPointQr({qrcode:'68c3c681-708d-4f8b-87b0-167e7138126e',tenant:'nhance'}))
  }, [csrfLoaded]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_VISITOR_MANAGEMENT}v1/csrf`, { withCredentials: true })
      .then((response) => {
        if (response?.status === 200) {
          localStorage.setItem('csrfToken', response?.data?.csrf_token)
          setCsrfLoaded(true)
        }
      })
      .catch(error => console.log(error))
  }, [])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
  };

  const handleContextMenu = (e) => {
    e.preventDefault()
  }

  useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu)

    return () => {
        document.removeEventListener('contextmenu', handleContextMenu)  //After event handler is executed,remove it to prevent memeory leaks
    }
  }, [])

  //clickJacking prevention - frameBusting code
  useEffect(() => {
    if (window !== window.top) {
        window.top.location = window.location
    }
  }, [])

  return (
    <Box>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        {(loaded && csrfLoaded) ? (
          error ? (
            <SessionExpired error={error} />
          ) : (
            // <ThankYou />
            <Main />
          )
        ) : (
          // <OtpVerification />
          // <VisitorDetails />
          <Box>
            <Modal open={true}>
              <Box display="flex" alignItems={"center"} height="100vh">
                <Lottie options={defaultOptions} height={150} width={150} />
              </Box>
            </Modal>
          </Box>
        )}
      </ThemeProvider>
    </Box>
  );
}

export default App;
