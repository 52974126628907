import React from 'react'
import { IconButton, Box, Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
// import back from "../../Assets/Images/left-arrow.png";
import { setVisitorComp } from '../../Slice/ReduxSlice/CompSlice'
// import back from '../../../Assets/Images/back_new.png'
import back from '../../Assets/Images/back_new.png'
import ConfirmVisitorDetails from '../../Components/ConfirmVisitorDetails'
import { imageStyle, logoBoxStyle } from '../Styles/LoginStyles'
import CustomBox from '../Box/CustomBox'

const Header = (props) => {
  const comp = useSelector((state) => state?.visitorComp?.title)
  const logo = useSelector((state) => state.visitorDetails?.tenant?.logo.light)

  const { link } = props
  const dispatch = useDispatch()
  const handleClick = () => {
    // Add your handle click logic here
    comp === 'VisitorForm'
      ? dispatch(setVisitorComp('otpPage'))
      : comp === 'OtpVerification'
      ? dispatch(setVisitorComp('otpPage'))
      : comp === 'VisitorDetails'
      ? dispatch(setVisitorComp('otpPage'))
      : comp === 'ConfirmVisitorDetails'
      ? dispatch(setVisitorComp('VisitorForm'))
      : dispatch(setVisitorComp('otpPage'))
  }

  return (
    <Grid
      container
      spacing={0}
      display="flex"
      alignItems={'center'}
      height={70}
      // backgroundColor="#FF6520"
      mb={0}
      p={2}
    >
      {comp !== 'addVisitorDetails' && (
        <>
          <Grid item container xs={1.2}>
            <IconButton size="small" float="left" onClick={handleClick}>
              <img
                src={back}
                alt="Back"
                width={'40px'}
                height={'40px'}
                resizeMode="contain"
              />
            </IconButton>
          </Grid>
          <Grid item xs={10.8}>
            {comp === 'ConfirmVisitorDetails' && (
              <CustomBox sx={{display:'flex', justifyContent:'center',alignItems:'flex-start'}}>
                <Box component="img" sx={imageStyle} src={logo} />
              </CustomBox>
            )}
          </Grid>
        </>
      )}
      {/* <Grid item xs={10.8} display="flex">
                <Box component='img' sx={{ height: '30px', width: '110px' }} src='https://twin-development.s3.ap-south-1.amazonaws.com/$2b$10$ZomMy8IfbyX1fy1ZkYmlW..svg' />
            </Grid> */}
    </Grid>
  )
}

export default Header
