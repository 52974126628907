export const commonAlign={display:'flex',justifyContent:'center',width:'100%'}

export const commonFontSize={fontSize:'16px'}

export const alignStyle={display:'flex',alignItems:'center'}

export const mtJustifyStyle={mt:'1vh',display:'flex',justifyContent:'center'}

export const commonJustifyContent={width:'100%',justifyContent:'center',display:'flex'}

export const defaultFontSizeStyle={fontSize:'12px',textAlign:'center'}