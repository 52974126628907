import React from "react";
import Lottie from "react-lottie";
import CustomBox from "../Box/CustomBox";
import expired from "../../Assets/Animations/expired.json";
import CustomText from "../Text/CustomText";
import {
  getRelativeHeight,
  getRelativeWidth,
} from "../../Helpers/RelativeSize";

const SessionExpired = ({ error }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: expired,
  };
  return (
    <CustomBox
      sx={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CustomBox>
        <CustomBox sx={{ p: 0, width: "100vw" }}>
          <Lottie
            style={{
              height: getRelativeHeight("300px"),
              width: getRelativeWidth("300px"),
            }}
            options={defaultOptions}
          />
          <CustomBox sx={{px:'4px'}}>
            <CustomText sx={{ fontSize: "22px", textAlign: "center" }}>
              {error}
            </CustomText>
          </CustomBox>
        </CustomBox>
      </CustomBox>
    </CustomBox>
  );
};

export default SessionExpired;
