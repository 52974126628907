import { Box } from '@mui/material'
import React from 'react'
import { getRelativeHeight, getRelativeWidth } from '../../Helpers/RelativeSize'

const CustomBox = (props) => {
    const style=props?.sx
    const sx={...style}
    const handleToScaleWidth=(prop,val)=>{
        const shouldScale=(!((String(val)?.includes('%'))||(String(val)?.includes('vw'))))
        if(shouldScale){sx[prop]=getRelativeWidth(val)}
    }
    const handleToScaleHeight=(prop,val)=>{
        const shouldScale=(!((String(val)?.includes('%'))||(String(val)?.includes('vh'))))
        if(shouldScale){sx[prop]=getRelativeHeight(val)}
    }
   
    if(sx?.height) handleToScaleHeight('height',sx?.height)
    if(sx?.width) handleToScaleWidth('width',sx?.width)
    if(sx?.p) handleToScaleWidth('p',sx?.p)
    if(sx?.px) handleToScaleWidth('px',sx?.px)
    if(sx?.py) handleToScaleHeight('py',sx?.py)
    if(sx?.pt) handleToScaleHeight('pt',sx?.pt)
    if(sx?.pb) handleToScaleHeight('pb',sx?.pb)
    if(sx?.pl) handleToScaleWidth('pl',sx?.pl)
    if(sx?.pr) handleToScaleWidth('pr',sx?.pr)
    if(sx?.m) handleToScaleWidth('m',sx?.m)
    if(sx?.mx) handleToScaleWidth('mx',sx?.mx)
    if(sx?.my) handleToScaleHeight('my',sx?.my)
    if(sx?.mt) handleToScaleHeight('mt',sx?.mt)
    if(sx?.mb) handleToScaleHeight('mb',sx?.mb)
    if(sx?.mr) handleToScaleWidth('mr',sx?.mr)
    if(sx?.ml) handleToScaleWidth('ml',sx?.ml)
    if(sx?.left) handleToScaleWidth('left',sx?.left)
    if(sx?.right) handleToScaleWidth('right',sx?.right)
    if(sx?.borderRadius) handleToScaleWidth('borderRadius',sx?.borderRadius)
    if(sx?.boxShadow) handleToScaleWidth('boxShadow',sx?.boxShadow)
    
  return (
   <Box sx={sx}>{props.children}</Box>
  )
}

export default CustomBox
