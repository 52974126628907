import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FILEPOST, GET, GET_CSRF, POST, PUT } from "../../Helpers/http";

const initialState = {
  isLoaded: { loaded: false, error: "" },
  tenant: {
    building: "",
    location: "",
    tenantCode: "",
    fileReadUrl: "",
    logo: "",
  },
  profileCompletionUrl: false,
  phoneNumber: { number: "", phoneNumberIsLoaded: false, countryCode: "" },
  email: { mailId: "", mailIdIsLoaded: false },
  otpSent: false,
  notificationMode: "",
  otpStatus: { isLoading: false, message: "", profileCreated: false },
  stepsData: {
    data: ["Personal Info", "ID proof", "Terms and conditions"],
    currentStep: 0,
  },
  idProof: {
    type: "none",
    file: "",
    profileImage: null,
    proofList: [],
    proofListIsLoaded: false,
  },
  personalInfo: {
    name: "",
    email: "",
    phone: "",
    companyName: "",
    emailIsError: false,
    visitorId: "",
    countryCode: "",
    createNewProfile: false,
  },
  termsConditions: { agreed: false, general: "", health: "" },
  secureKey: "",
  existingVisitorDetails: {
    name: "",
    email: "",
    phone: "",
    company: "",
    isLoaded: false,
    countryCode: "",
  },
};

export const startValidateEntryPointQr = createAsyncThunk(
  "VisitorSlice/startValidateEntryPointQr",
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const response = await POST(`v1/entrypointqr/validate`, body);
      if (response.status === 200) {
        dispatch(setIsLoaded());
        localStorage.setItem("qrcode", body.qrcode);
        localStorage.setItem("tenant", body.tenant);
        // return response?.data
        dispatch(setQrData(response?.data));
      } else {
        console.log({ response });
      }
    } catch (err) {
      dispatch(
        setIsLoaded({
          error:
            "Oops, session has expired. Please refresh the qr code on the kiosk and scan again!",
        })
      );
    }
  }
);

export const startValidateEncryptedString = createAsyncThunk(
  "VisitorSlice/startValidateEncryptedString",
  async ({ body, handleSendOtp }, { dispatch, rejectWithValue }) => {
    try {
      const response = await POST(`v1/visitor/complete/profile/validate`, body);
      if (response.status === 200) {
        const data = response?.data;
        console.log({ response });
        dispatch(setProfileCompletionUrl());
        dispatch(
          setQrData({
            building: data?.building?._id,
            location: data?.location?._id,
            tenantCode: data?.tenantCode,
            fileReadUrl: data?.fileReadUrl,
            logo: data?.logo,
          })
        );
        handleSendOtp &&
          handleSendOtp({
            tenantCode: data?.tenantCode,
            email: data?.visitor?.email,
          });

        // state.tenant.building = action.payload?.building;
        // state.tenant.location = action.payload.location;
        // state.tenant.tenantCode = action.payload.tenantCode;
        // state.tenant.fileReadUrl = action.payload.fileReadUrl;
        // state.tenant.logo = action.payload.logo;

        // dispatch(setIsLoaded());
        // localStorage.setItem("qrcode", body.qrcode);
        // localStorage.setItem("tenant", body.tenant);
        // // return response?.data;
        // dispatch(setQrData(response?.data))
      } else {
        console.log({ response });
      }
    } catch (err) {
      dispatch(
        setIsLoaded({
          error:
            "Oops, session has expired. Please refresh the qr code on the kiosk and scan again!",
        })
      );
    }
  }
);

export const startUpdateVerificationMethod = createAsyncThunk(
  "VisitorSlice/startUpdateVerificationMethod",
  async ({ data, toggleLoader }, { dispatch }) => {
    try {
      const response = await POST(`v1/visitor/validate/identity`, data);
      if (response.status === 200) {
        toggleLoader && toggleLoader();
        return response?.data;
      } else {
        console.log({ response });
      }
    } catch (err) {
      if (err?.response?.status === 500) {
        alert("Error in sending OTP. Please try again");
        toggleLoader();
      }
      console.log("error", err);
    }
  }
);

export const startValidateOtp = createAsyncThunk(
  "VisitorSlice/startValidateOtp",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await POST(`v1/visitor/verify/otp`, data);
      if (response.status === 200) {
        if (response?.data?.token) {
          localStorage.setItem("token", response?.data?.token);
        }
        return response?.data;
      } else {
        console.log({ response });
      }
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const startUploadFile = createAsyncThunk(
  "VisitorSlice/startUploadFile",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await FILEPOST(`v1/visitor/upload/file`, data?.formdata);
      if (response?.status === 201) {
        data.handleImageUploadSuccess(response?.data);
      } else {
        console.log({ response });
      }
    } catch (err) {
      console.log({ err });
    }
  }
);

export const startFetchAgreement = createAsyncThunk(
  "VisitorSlice/startFetchAgreement",
  async (_, { dispatch }) => {
    try {
      const response = await GET(`v1/visitor/agreements`);
      if (response?.status === 200) {
        return response?.data;
      } else {
        console.log({ response });
      }
    } catch (err) {
      console.log({ err });
    }
  }
);

export const startCreateVisitorProfile = createAsyncThunk(
  "VisitorSlice/startCreateVisitorProfile",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await PUT(
        `v1/visitor/complete/profile/${data.id}`,
        data.profile
      );
      if (response.status === 200) {
        data.handleVisitorProfileCreation();
      }
    } catch (err) {
      if (
        err?.response?.data?.message ===
        "Contact details exist for another visitor"
      ) {
        alert("Contact details exist for another visitor");
      }
      console.log({ err });
    }
  }
);

export const startCreateNewProfile = createAsyncThunk(
  "VisitorSlice/startCreateNewProfile",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await POST(`v1/visitor/create/new`, data.profile);
      if (response.status === 201) {
        localStorage.setItem("token", response?.data?.token);
        data.handleVisitorProfileCreation();
      }
    } catch (err) {
      if (
        err?.response?.data?.message ===
        "Contact details exist for another visitor"
      ) {
        alert("Contact details exist for another visitor");
      }
      console.log({ err });
    }
  }
);

export const startCreateAppointment = createAsyncThunk(
  "VisitorSlice/startCreateAppointment",
  async (
    { data, handleAppointmentSuccess, handleError, toggleLoading },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await POST(`v1/visitor/appointment`, data);
      if (response.status === 201) {
        toggleLoading();
        handleAppointmentSuccess();
      } else {
        console.log({ response });
      }
    } catch (err) {
      toggleLoading();
      if (err?.response?.status === 403) {
        handleError(
          "You already have an appointment booked for the current time!"
        );
      } else {
        handleError("Oops something went wrong!");
      }
      console.log({ err });
    }
  }
);

export const startFetchIdTypes = createAsyncThunk(
  "VisitorSlice/startFetchIdTypes",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await GET(`v1/visitor/idtypes`);
      if (response.status === 200) {
        return response.data;
      } else {
        console.log({ response });
      }
    } catch (err) {
      console.log({ err: err?.response });
    }
  }
);

const VisitorSlice = createSlice({
  name: "VisitorSlice",
  initialState,
  reducers: {
    setIsLoaded: (state, action) => {
      state.isLoaded.loaded = true;
      state.isLoaded.error = action.payload?.error;
    },
    setProfileCompletionUrl: (state, action) => {
      state.profileCompletionUrl = true;
    },
    clearProfileCompletionUrl: (state, action) => {
      state.profileCompletionUrl = false;
    },
    setNotificationMode: (state, action) => {
      if (action.payload?.mode === "phone") {
        state.phoneNumber.number = action.payload.id;
        state.phoneNumberIsLoaded = true;
      } else {
        state.email.mailId = action.payload.id;
        state.mailIdIsLoaded = true;
      }
      state.notificationMode = action.payload.mode;
      state.otpSent = true;
    },
    setOtpLoading: (state, action) => {
      state.otpStatus.isLoading = true;
    },
    setOtpError: (state, action) => {
      state.otpStatus.message = "success";
      state.otpStatus.isLoading = false;
    },
    setUpdatedStep: (state, action) => {
      state.stepsData.currentStep = action.payload
        ? action.payload?.step
        : state.stepsData.currentStep + 1;
    },
    setIdProof: (state, action) => {
      if (action.payload?.fileType)
        state.idProof.type = action.payload.fileType;

      if (action.payload?.file) state.idProof.file = action.payload.file;
      if (action.payload?.awsFile)
        state.idProof.awsFile = action.payload?.awsFile;
    },
    setPersonalInfo: (state, action) => {
      const data = action.payload;
      if (data?.name || data?.name === "") state.personalInfo.name = data.name;
      if (data.email || data?.email === "")
        state.personalInfo.email = data.email;
      if (data.phoneNumber || data?.phoneNumber === "")
        state.personalInfo.phone = data.phoneNumber;
      if (data.company || data?.company === "")
        state.personalInfo.companyName = data.company;
      if (data.countryCode || data?.countryCode === "")
        state.personalInfo.countryCode = data.countryCode;
    },
    setEmailIsError: (state, action) => {
      state.personalInfo.emailIsError = action.payload;
    },
    setTermsAndConditions: (state, action) => {
      state.termsConditions.agreed = action.payload.agreed;
    },
    setProfileImage: (state, action) => {
      state.idProof.profileImage = action.payload;
    },
    updateCountryCode: (state, action) => {
      state.phoneNumber.countryCode = action.payload;
    },
    setNewProfile: (state, action) => {
      state.personalInfo.createNewProfile = true;
    },
    setQrData: (state, action) => {
      if (action.payload) {
        state.tenant.building = action.payload?.building;
        state.tenant.location = action.payload.location;
        state.tenant.tenantCode = action.payload.tenantCode;
        state.tenant.fileReadUrl = action.payload.fileReadUrl;
        state.tenant.logo = action.payload.logo;
      }
    },
    clearVisitorDetails: (state, action) => {
      state.personalInfo.name = "";
      state.personalInfo.phone = "";
      state.personalInfo.email = "";
      state.personalInfo.companyName = "";
      state.personalInfo.countryCode = "";
      state.personalInfo.createNewProfile = "";
      state.personalInfo.visitorId = "";
      state.personalInfo.emailIsError = "";
      state.idProof = {
        type: "none",
        file: "",
        profileImage: null,
        proofList: [],
        proofListIsLoaded: false,
      };
      state.stepsData.currentStep = 0;
    },
    clearOtpSent: (state, action) => {
      state.otpSent = false;
      state.phoneNumber = { number: "", phoneNumberIsLoaded: false };
      state.email = { mailId: "", mailIdIsLoaded: false };
      state.otpStatus = {
        isLoading: false,
        message: "",
        profileCreated: false,
      };
    },
    clearAllData: (state, action) => (state = initialState),
  },
  extraReducers(builder) {
    // builder.addCase(startValidateEntryPointQr.fulfilled,(state,action)=>{
    //     if(action.payload){
    //     state.tenant.building=action.payload?.building
    //     state.tenant.location=action.payload.location
    //     state.tenant.tenantCode = action.payload.tenantCode
    //       state.tenant.fileReadUrl = action.payload.fileReadUrl
    //       state.tenant.logo = action.payload.logo
    //     }

    // })
    builder.addCase(
      startUpdateVerificationMethod.fulfilled,
      (state, action) => {
        if (action.payload) {
          if (action.payload?.contactMethod === "mobile") {
            state.phoneNumber.number = action.payload.contactDetail;
            state.phoneNumberIsLoaded = true;
            state.personalInfo.phone = action.payload.contactDetail;
          } else {
            state.email.mailId = action.payload.contactDetail;
            state.mailIdIsLoaded = true;
            state.personalInfo.email = action.payload.contactDetail;
          }
          state.notificationMode = action.payload.contactMethod;
          state.secureKey = action.payload?.secureKey;
          state.otpSent = true;
        }
      }
    );

    builder.addCase(startValidateOtp.fulfilled, (state, action) => {
      const response = action.payload;
      if (action.payload) {
        state.otpStatus.message = "success";
        state.otpStatus.isLoading = false;
        state.personalInfo.visitorId = response?.visitorDetails?._id;
        state.otpStatus.profileCreated =
          response?.visitorDetails?.isProfileCompleted;
        state.existingVisitorDetails.name = response?.visitorDetails?.name;
        state.existingVisitorDetails.company =
          response?.visitorDetails?.companyName;
        state.existingVisitorDetails.email = response?.visitorDetails?.email;
        state.existingVisitorDetails.phone = response?.visitorDetails?.mobile;
        state.existingVisitorDetails.countryCode =
          response?.visitorDetails?.countryCode;
        state.existingVisitorDetails.isLoaded = true;
      }
    });
    builder.addCase(startValidateOtp.rejected, (state, action) => {
      console.log("errrrrr", action?.payload);
      if (action.payload?.error) {
        if (action.payload?.error === "Invalid OTP") {
          state.otpStatus.message = "fail";
          state.otpStatus.isLoading = false;
        }
      }
    });
    builder.addCase(startFetchAgreement.fulfilled, (state, action) => {
      if (action.payload) {
        state.termsConditions.health = action.payload?.health;
        state.termsConditions.general = action.payload?.general;
      }
    });
    builder.addCase(startFetchIdTypes.fulfilled, (state, action) => {
      if (action.payload) {
        state.idProof.proofList = action.payload;
        state.idProof.proofListIsLoaded = true;
      }
    });
  },
});

export const {
  setIsLoaded,
  setNotificationMode,
  setQrData,
  clearVisitorDetails,
  setNewProfile,
  updateCountryCode,
  setOtpLoading,
  setOtpError,
  setUpdatedStep,
  setIdProof,
  setPersonalInfo,
  setTermsAndConditions,
  setEmailIsError,
  setProfileImage,
  setProfileCompletionUrl,
  clearProfileCompletionUrl,
  clearOtpSent,
} = VisitorSlice.actions;

export default VisitorSlice.reducer;
