import React from 'react'
import CustomText from '../Text/CustomText'
import CustomBox from './CustomBox'

const OtpBox = (props) => {
    const {title,subTitle,textDecoration,textDecorationText}=props
  return (
    <>
      <CustomBox sx={{mt:'4vh'}}>
                <CustomText sx={{textAlign:'center',fontSize:'16px',fontWeight:600}}>{title}</CustomText>
            </CustomBox>
            <CustomBox sx={{display:'flex',justifyContent:'center',mt:'1vh'}}>
                <CustomText sx={{textAlign:'center',fontSize:'16px'}}>{`${subTitle}\u00A0\ `}</CustomText>
                {textDecoration&& <CustomText sx={{textAlign:'center',fontSize:'16px',textDecoration:'underline'}}>{`${textDecorationText}`}</CustomText>}
            </CustomBox>
    </>
  )
}

export default OtpBox
