import * as React from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import moment from 'moment'
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CardComp from '../UI/Card/CardComp'
import { DayPicker,useNavigation } from 'react-day-picker'
import 'react-day-picker/dist/style.css';
import BottomButton from '../UI/ManageVisitor/Button/Button';
import {ClockPicker}  from '@mui/x-date-pickers';
// import { TimeClock } from '@mui/x-date-pickers/TimeClock';
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import CustomBox from '../UI/Box/CustomBox'
import CustomText from '../UI/Text/CustomText'
import { setVisitorComp } from '../Slice/ReduxSlice/CompSlice'
import { setVisitDateTime, setHostNames, setBuildingName } from '../Slice/ReduxSlice/VisitorInfoSlice';
import ConfirmButton from '../UI/Button/ConfirmButton';
import { useEffect } from 'react';



const VisitDate = () => {
    const dispatch = useDispatch();

    const [disabled,setDisabled]=useState(true)
    const defaultDate = new Date(); 
    const defaultTime = moment(defaultDate).format('HH:mm'); 
    const [selectedDate, setSelectedDate] = useState(defaultDate);
    const [time, setTime] = useState(defaultTime);
    const durations=[{label:'30 minutes',value:30},{label:'1 hour',value:60},{label:'2 hour',value:120},{label:'3 hour',value:180}]
    const [selectedDuration,setSelectedDuration]=useState('Select Duration')

    const [value, setValue] = useState(dayjs(moment().format('YYYY-MM-DDTHH:mm')));
    const [currentView, setCurrentView] = useState('hours');
    const handleChange = (val) => {

        setValue(val)
        setTime(moment(val?.$d).format('HH:mm'))
        setCurrentView(currentView == 'hours' ? 'minutes' : 'hours')
    }
    const handleDuration=(e)=>{
        const val=e.target?.value
        setSelectedDuration(val)
    }

    const handleNext = () => {
        const startTime=moment(selectedDate).format('YYYY-MM-DD')
        const startTimeGMT=new Date(`${startTime} ${time}`)
        const startTimeISO=startTimeGMT.toISOString()
        const endTime=startTimeGMT
        endTime.setMinutes(endTime.getMinutes()+selectedDuration)
        const endTimeISO=endTime.toISOString()
        dispatch(setVisitDateTime({ date: selectedDate, time: { selectedTime: time, gmtTime: value?.$d },startTime:startTimeISO,endTime:endTimeISO }))
        dispatch(setVisitorComp('VisitorForm'))
    }
    
    const handleDayClick=(date)=>{
        setSelectedDate(date)
      }
      function CustomCaption(CaptionProps) {
        const { goToMonth, nextMonth, previousMonth } = useNavigation()
        let props = CaptionProps
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              disabled={!previousMonth}
              onClick={() => previousMonth && goToMonth(previousMonth)}
            >
              <ArrowBackIosIcon />
            </Button>
            {moment(props.displayMonth).format('MMMM YYYY')}
            <Button
              disabled={!nextMonth}
              onClick={() => nextMonth && goToMonth(nextMonth)}
            >
              <ArrowForwardIosIcon />
            </Button>
          </Box>
        )
      }

      useEffect(()=>{
        if(selectedDate&&time&&(selectedDuration!=='Select Duration')){
            setDisabled(false)
        }
        else {
            setDisabled(true)
        }
      },[selectedDate,time,selectedDuration])
    return (
        <>
            <CustomBox sx={{ mt: '16px',p:'16px' }}>
                <CustomBox >
                    <CustomText sx={{ fontSize: '16px', fontWeight: '600' }}>Select the date and time of your visit</CustomText>
                    <CustomBox sx={{ mt: '16px' }}>
                        <CardComp>
                        <DayPicker
                        mode="single"
                        required
                        selected={selectedDate}
                        onDayClick={(date) => handleDayClick(date)}
                        components={{
                            Caption: CustomCaption,
                        }}
                       
                        disabled={[
                            { before: new Date() },
                            (date) => {
                            const today = new Date()
                            const yesterday = new Date(
                                Date.now() - 86400000,
                            ).getDate()
                            const maxAllowedDate = new Date()
                            maxAllowedDate.setDate(today.getDate() + 29)
                            const nextMonth = new Date(
                                today.getFullYear(),
                                today.getMonth() + 1,
                            )
                            nextMonth.setDate(yesterday)
                            return ((date > maxAllowedDate))
                            },
                        ]}
                        // modifiers={{
                        //     disabled: [
                        //       {
                        //         daysOfWeek: [0, 6]
                        //       },
                        //       {
                        //         before: new Date(2023, 6, 13)
                        //       }
                        //     ]
                        //   }}
                        style={{ fontSize: '12px' }}
                        />
                        </CardComp>
                    </CustomBox>
                    <CustomBox sx={{ mt: '16px' }}>
                        <CustomText sx={{ textAlign:'center',fontSize:'16px' }}  >Select Time</CustomText>
                        <Grid container >
                            <Grid item xs={12} display='flex' justifyContent={'center'}  >
                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['TimeClock', 'TimeClock', 'TimeClock']}>
                                        <DemoItem>
                                            <TimeClock
                                                date={value}
                                                defaultValue={dayjs(`${dayjs().format('YYYY-MM-DD')}T${currentTime}`)}                                                onChange={(time) => handleChange(time)}
                                                views={['hours', 'minutes']}
                                                ampm={false}
                                            />
                                        </DemoItem>
                                    </DemoContainer>
                                </LocalizationProvider> */}
                                 <LocalizationProvider dateAdapter={AdapterDayjs}> 
                                    <ClockPicker 
                                    // minTime={new Date(0, 0, 0, 9, 0)}
                                    date={value} onChange={(time)=>handleChange(time)} autoFocus={true} openTo={'hours'} view={currentView} views={['hours','minutes']} />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <CustomBox>
                            <CustomText sx={{ textAlign: 'center', fontSize: '16px' }} >
                                {moment(value?.$d).format('HH:mm')}
                            </CustomText>
                        </CustomBox>
                    </CustomBox>
                </CustomBox>
            </CustomBox>
            <CustomBox sx={{p:'16px'}}>
            <Select
                value={selectedDuration}
                onChange={handleDuration}
                fullWidth
                inputProps={{ 'aria-label': 'Without label' }}
                placeholder='Select Duration'
                 >
                    <MenuItem value="Select Duration">
                        {"Select Duration"}
                    </MenuItem>
                    {/* <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                    {durations.map((ele)=>{
                        return (
                        <MenuItem value={ele.value}>{ele.label}</MenuItem>
                        )
                    })}
             </Select>
            </CustomBox>
            <CustomBox sx={{ p:'16px' }}>
                <ConfirmButton title={'Next'} disabled={disabled} backgroundColor='#FF6520' onclick={handleNext} width={'100%'} />
            </CustomBox>

        </>
    )
}

export default VisitDate