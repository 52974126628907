import { Box, Grid, Stack, TextField, Autocomplete } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  locationTitleContainer,
  titleText,
} from "../UI/Styles/ManageVisitorStyles/DateSelectorStyles";
import VisitorData from "../UI/InputField/VisitorData";
import BottomButton from "../UI/ManageVisitor/Button/Button";
import CustomBox from "../UI/Box/CustomBox";
import CustomText from "../UI/Text/CustomText";
import { setVisitorComp } from "../Slice/ReduxSlice/CompSlice";
import {
  setAppointmentInfo,
  startFetchHostByName,
} from "../Slice/ReduxSlice/VisitorInfoSlice";
import PurposeChips from "../UI/Card/PurposeChips";
import ConfirmButton from "../UI/Button/ConfirmButton";
import { imageStyle, logoBoxStyle } from "../UI/Styles/LoginStyles";

const VisitorForm = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [hostInput, setHostInput] = useState("");
  const [selectedPurpose, setSelectedPurpose] = useState("");
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const [selectedHost, setSelectedHost] = useState("");
  const inputRef = useRef(null); // Reference for the input field

  const visitPurposes = [
    "OFFICIAL",
    "PERSONAL",
    "DELIVERY",
    "TRAINING",
    "INTERVIEW",
    "OTHER",
  ];

  const hostNames = useSelector((state) => state?.visitorInfo?.hostNames);
  const logo = useSelector(
    (state) => state.visitorDetails?.tenant?.logo?.light
  );

  const isNextButtonDisabled = () => {
    return !hostInput || !selectedPurpose;
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setHostInput(inputValue);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handlePurposeClick = (val) => {
    setSelectedPurpose(val);
  };

  const handleNextClick = () => {
    if (!isNextButtonDisabled()) {
      const data = {
        host: selectedHost,
        purpose: selectedPurpose === "OTHER" ? description : selectedPurpose,
      };
      dispatch(setAppointmentInfo(data));
      dispatch(setVisitorComp("ConfirmVisitorDetails"));
    }
  };

  useEffect(() => {
    if (hostInput.length < 2) setShowOptions(false);
    const fetchNames = setTimeout(() => {
      if (hostInput.trim() !== "" && hostInput.length > 2) {
        dispatch(startFetchHostByName(hostInput));
        setShowOptions(true); // Show options when the user enters at least one character
      } else {
        setShowOptions(false); // Hide options when the input field is empty
      }
    }, 600);

    return () => clearTimeout(fetchNames);
  }, [hostInput]);

  useEffect(() => {
    const handleFocus = () => {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 300); // Delay to allow keyboard to open
    };

    const inputElement = inputRef.current;

    if (inputElement) {
      inputElement.addEventListener("focus", handleFocus);
      return () => {
        inputElement.removeEventListener("focus", handleFocus);
      };
    }
  }, []);

  return (
    <>
      <Box sx={{ mb: "25px" }}>
        <CustomBox sx={logoBoxStyle}>
          <Box component="img" sx={imageStyle} src={logo} />
        </CustomBox>
      </Box>
      <CustomBox sx={{ mt: "16px", p: "16px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CustomText sx={titleText}>Who are you meeting?</CustomText>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              value={selectedHost}
              options={hostNames}
              defaultValue={""}
              onClose={() => setShowOptions(false)}
              // onOpen={() => setShowOptions(true)}
              getOptionLabel={(option) =>
                option?.fullName ? option?.fullName : ""
              }
              filterOptions={(options, { inputValue }) => {
                if (inputValue?.length < 2) {
                  return []; // Return an empty array if the input has less than two characters
                }

                return options.filter((option) =>
                  option?.fullName
                    ?.toLowerCase()
                    .includes(inputValue?.toLowerCase())
                );
              }}
              open={showOptions}
              openOnFocus={false}
              onChange={(event, newValue) => {
                setSelectedHost(newValue);
              }}
              style={{ backgroundColor: "white" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: null,
                  }}
                  onChange={handleInputChange}
                  placeholder="Enter name"
                  inputRef={inputRef} // Attach ref to input
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={"20%"}>
          <Grid item xs={12}>
            <CustomText sx={titleText}>
              What is the purpose of your invitation?
            </CustomText>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} mt={1}>
              <PurposeChips
                visitPurposes={visitPurposes.slice(0, 3)}
                selectedPurpose={selectedPurpose}
                handlePurposeClick={handlePurposeClick}
              />
            </Stack>
            <Stack direction="row" spacing={1} mt={1}>
              <PurposeChips
                visitPurposes={visitPurposes.slice(3)}
                selectedPurpose={selectedPurpose}
                handlePurposeClick={handlePurposeClick}
              />
            </Stack>
            {selectedPurpose === "OTHER" && (
              <CustomBox sx={{ mt: "10px" }}>
                <VisitorData
                  value={description}
                  placeholder="Enter the purpose"
                  name="description"
                  width="100%"
                  onChange={handleDescriptionChange}
                />
              </CustomBox>
            )}
          </Grid>
        </Grid>
        <CustomBox
          sx={{ display: "flex", justifyContent: "center", mt: "60%" }}
        >
          <ConfirmButton
            title={"REVIEW"}
            backgroundColor="#FF6520"
            onclick={handleNextClick}
            disabled={isNextButtonDisabled()}
            width={"80%"}
          />
        </CustomBox>
      </CustomBox>
    </>
  );
};

export default VisitorForm;
