import { Box } from '@mui/material'
import React from 'react'
import ConfirmButton from '../../Button/ConfirmButton'

const BottomButton = ({ onclick, title, disabled }) => {
    return (

        <Box style={{
            position: 'absolute',
            bottom: 10,
            width: '92%',
            display: 'flex',
            justifyContent: 'center'
        
        }} >
            <ConfirmButton backgroundColor='#FF6520' disabled={disabled} title={title || 'Next'} width={'100%'} onclick={onclick} />
        </Box>

    )
}

export default BottomButton
