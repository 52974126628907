import { FormControl, IconButton, MenuItem, Select } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import CustomBox from '../Box/CustomBox'
import CustomText from '../Text/CustomText'
import { lightTheme } from "../../Helpers/ThemeHandler"
import { useDispatch, useSelector } from 'react-redux'
import { setIdProof, startFetchIdTypes, startUploadFile } from '../../Slice/ReduxSlice/VisitorSlice'

const IdProof = () => {
    const selectedIdProof=useSelector(state=>state.visitorDetails.idProof)

    const dispatch=useDispatch()
    const inputFile = useRef(null);
    const proofOptions=selectedIdProof?.proofListIsLoaded?selectedIdProof?.proofList:[]
    const [selectedProof,setSelectedProof]=useState('')
    const handleIdProofChange=(e)=>{
        dispatch(setIdProof({fileType:e.target?.value}))
    }

  
    const handleFileChange = e => {
        
       const selectedFile=e.target.files[0];
       setSelectedProof(selectedFile)
       let formData = new FormData()
       formData.append('file', selectedFile)

       const handleImageUploadSuccess=(awsFile)=>{
        dispatch(setIdProof({file:selectedFile,awsFile}))
    }
    
       dispatch(startUploadFile({formdata:formData,handleImageUploadSuccess}))

       

    //    dispatch(setIdProof({file:selectedFile}))
       
      }

      useEffect(()=>{
        dispatch(startFetchIdTypes())
      },[])
  return (
    <CustomBox sx={{pt:'1vh'}}>
      <FormControl sx={{width:'100%' }}>
        <Select
          value={selectedIdProof?.type}
          onChange={handleIdProofChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="none">
            <em>None</em>
          </MenuItem>
          {proofOptions.map((option,i)=>{
            return (
                <MenuItem value={option} key={option}>{option?.name}</MenuItem>
            )
          })}
        
        </Select>
      </FormControl>
      <IconButton sx={{width:'100%',p:0}}  onClick={() => inputFile?.current?.click()}   >
        <CustomBox sx={{mt:'2vh',height:'150px',width:'100%',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:lightTheme?.palette?.nhance.grey2,border:'dashed 1px'}}>
                <CustomBox>
                    <CustomText sx={{fontSize:selectedIdProof?.file?'18px':'16px',textAlign:'center',fontWeight:600}}>{selectedIdProof?.file?selectedIdProof?.file?.name:'Select File'}</CustomText>
                    {selectedIdProof?.file&&<CustomText sx={{fontSize:'14px',textAlign:'center'}}>Click to choose again</CustomText>}
                </CustomBox>
        </CustomBox> 
        
      </IconButton>   
      <input
        type="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </CustomBox>
  )
}

export default IdProof
