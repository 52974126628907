import {Chip, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { locationTitleContainer, titleText } from '../UI/Styles/ManageVisitorStyles/DateSelectorStyles';
import VisitorData from '../UI/InputField/VisitorData';
import VisitorInput from '../UI/InputField/VisitorInput';
import BottomButton from '../UI/ManageVisitor/Button/Button';
import { useDispatch } from 'react-redux';
import CustomBox from '../UI/Box/CustomBox';
import CustomText from '../UI/Text/CustomText';
import { setVisitorComp } from '../Slice/ReduxSlice/CompSlice';

const VisitDetails = () => {
    const [visitorEmail, setVisitorEmail] = useState('');
    const [isError, setIsError] = useState(false)
    const [selectedPurpose, setSelectedPurpose] = useState('');
    const [isEmailError, setIsEmailError] = useState(false);
    const [description, setDescription] = useState('');
    const dispatch = useDispatch();
    const visitPurposes = ['Interview', 'Official Business', 'Business Pitch', 'Meeting', 'Delivery', 'Other'];


    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleChange = (event) => {
        setVisitorEmail(event.target.value);
        setIsEmailError(false);
        setIsError(!isEmail(event.target.value.trim()));
    }

    const handleGuestInputBlur = () => {
        if (visitorEmail.length > 0 && !isEmail(visitorEmail.trim())) {
            setIsEmailError(true);
        }
    };

    const handlePurposeClick = (val) => {
        setSelectedPurpose(val);
    };

    const handleNextClick = () => {
        if (visitorEmail && isEmail(visitorEmail.trim()) && selectedPurpose) {
            const data = {
                visitor: visitorEmail.trim(),
                purpose: selectedPurpose === 'Other' ? description : selectedPurpose,
            };
            dispatch(setVisitorComp('ConfirmVisitorDetails'));
            // Additional code to handle the data
        } else {
            setIsEmailError(true);
        }
    };


    return (
        <CustomBox sx={{p:16}}>
        <CustomBox sx={locationTitleContainer}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CustomText sx={titleText}>Who are you meeting?</CustomText>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <VisitorData
                            placeholder="Enter email ID"
                            height="6vh"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleGuestInputBlur}
                            value={visitorEmail}
                            isError={isEmailError}
                            style={{ color: isError ? 'red' : 'inherit' }}
                        />

                        {isEmailError && !isEmail(visitorEmail.trim()) && (
                            <CustomText color="red">Invalid email</CustomText>
                        )}
                    </Grid>

                </Grid>
            </Grid>
            <Grid container spacing={1} mt={'8px'}>
                <Grid item xs={12}>
                    <CustomText sx={titleText}>What is the purpose of your invitation?</CustomText>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1} maxWidth="100vw" flexWrap="wrap">
                        {visitPurposes?.slice(0, 3).map((purpose) => (
                            <CustomBox key={purpose}>
                                <Chip
                                    label={purpose}
                                    variant="outlined"
                                    onClick={() => handlePurposeClick(purpose)}
                                    size="medium"
                                    style={{
                                        backgroundColor: selectedPurpose === purpose ? '#FF6520' : '#FFFFFF',
                                        color: selectedPurpose === purpose ? '#FFFFFF' : '#000000',
                                        fontSize: '16px',
                                    }}
                                />
                            </CustomBox>
                        ))}
                    </Stack>
                    <Stack direction="row" spacing={1} mt={'1px'} maxWidth="100vw" flexWrap="wrap">
                        {visitPurposes?.slice(3).map((purpose) => (
                            <CustomBox key={purpose}>
                                <Chip
                                    label={purpose}
                                    variant="outlined"
                                    onClick={() => handlePurposeClick(purpose)}
                                    size="medium"
                                    style={{
                                        backgroundColor: selectedPurpose === purpose ? '#FF6520' : '#FFFFFF',
                                        color: selectedPurpose === purpose ? '#FFFFFF' : '#000000',
                                        fontSize: '16px',
                                    }}
                                />
                            </CustomBox>
                        ))}
                    </Stack>
                    {selectedPurpose === 'Other' &&  (
                        <CustomBox sx={{ mt: '10px' }}>
                            <VisitorInput
                                value={description}
                                placeholder="Enter the purpose"
                                name="description"
                                width="100%"
                                onChange={handleDescriptionChange}
                            />
                        </CustomBox>
                    )}
                </Grid>
            </Grid>
            <BottomButton
                onclick={handleNextClick}
                disabled={!visitorEmail || !selectedPurpose || isEmailError || !isEmail(visitorEmail.trim())}
            />

        </CustomBox>
        </CustomBox>
    );
};

export default VisitDetails;
