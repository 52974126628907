import { Typography } from '@mui/material'
import React from 'react'

const CustomText = (props) => {
    const fontSize=props?.sx?.fontSize?.split('px')
    const scaledFontSize=(`${((100/414)*fontSize[0])}vw`)
  return (
        
            <Typography sx={{...props.sx,fontSize:scaledFontSize}}>{props.children}</Typography>
        
  )
}

export default CustomText
