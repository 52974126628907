import {  FormControl, OutlinedInput } from '@mui/material'
import React from 'react'


const EmailInput = (props) => {
    const {email,handleChange,handleBlur,isError}=props

 
  return (
   
       <FormControl fullWidth={true}>
            <OutlinedInput
                placeholder={"Enter your email ID"}
                style={{ backgroundColor: '#FFFFFF',  "& .MuiOutlinedInput-notchedOutline" : {
                    borderColor : "#FF6520",
                 },
                "&:hover > .MuiOutlinedInput-notchedOutline" : {
                    borderColor : "#FF6520"
                 }, height:"6vh", width: '100%', borderRadius: "10px", color: (isError&&email.length!==0) ? "red" : "#000000" }}
                name={'email'}
                onBlur={handleBlur}
                value={email}
                onChange={handleChange}
                autoComplete='off'
            />
        </FormControl>
  )
}

export default EmailInput
