import React from 'react'
import { Box, Card } from '@mui/material'
import { styled } from '@mui/system'




export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none',
    borderRadius: '20px',
}

export const AlignedBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
})

export const inputStyle = {
    display: 'none',
}

