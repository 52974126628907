import { Button } from '@mui/material'
import React from 'react'
import { getRelativeWidth } from '../../Helpers/RelativeSize'

const ConfirmButton = (props) => {
    const { title, backgroundColor,onclick,disabled,height,width,color,borderRadius,borderColor } = props
    
    return (
        <Button sx={{ backgroundColor: backgroundColor, fontWeight:'bold',fontSize:'14px', color:color||'#F6F6F6',border:`solid 1px ${disabled?'none':(borderColor||'#FF6520')}`, width: width?width:'45%',height:height?height:'60px', textTransform: 'none',borderRadius:getRelativeWidth(borderRadius)||'0px','&:hover':{backgroundColor:backgroundColor} }}
            onClick={onclick}
            disabled={disabled}>
            {title}
        </Button>
    )
}

export default ConfirmButton